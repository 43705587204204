import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Gauge from "../Gauge/Gauge";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import ErrorDialog from "../Dialog/ErrorDialog";

export default function Login({ setShowLoading }) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [exchangeToken, setExchangeToken] = useState(null);
  const [code, setCode] = useState(null);
  const [loginStage, setLoginStage] = useState(0);
  const [openVerificationModal, setOpenVerificationModal] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showCodeErrorDialog, setShowCodeErrorDialog] = useState(false);

  let navigate = useNavigate();

  const queryClient = useQueryClient();

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log("Enter key was pressed. Run your function.");
        event.preventDefault();
        if (loginStage == 0) {
          onLogin();
        } else {
          onCodeSubmit();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [loginStage]);

  // Mutations
  const mutation = useMutation(
    () => {
      setShowLoading(true);
      return axios.post(`${process.env.REACT_APP_API_ROOT}/login`, {
        email,
        password,
      });
    },
    {
      onSuccess: (data) => {
        console.log(data);
        if (data.data && data.data.success && data.data.success.exchangeToken) {
          console.log(data.data);
          setLoginStage(1);
          setExchangeToken(data.data.success.exchangeToken);
          setShowLoading(false);
        } else {
          setShowLoading(false);
          setShowErrorDialog(true);
        }
      },
    }
  );

  const submitCodeMutation = useMutation(
    () => {
      setShowLoading(true);
      return axios.post(`${process.env.REACT_APP_API_ROOT}/verify2fa`, {
        exchangeToken: exchangeToken,
        code: code,
      });
    },
    {
      onSuccess: (data) => {
        if (data.data && data.data.success && data.data.success.token) {
          localStorage.setItem("tokenmob", data.data.success.token);

          queryClient.invalidateQueries(["user"]);
          queryClient.invalidateQueries(["myapps"]);
          queryClient.invalidateQueries(["myusers"]);
          queryClient.invalidateQueries(["inbox"]);
          setTimeout(() => {
            navigate("/dashboard");
            setShowLoading(false);
          }, 1000);
        } else {
          setShowLoading(false);
          setShowCodeErrorDialog(true);
        }
      },
    }
  );

  const onCodeSubmit = () => {
    submitCodeMutation.mutate();
  };

  const onLogin = () => {
    mutation.mutate();
  };

  return (
    <>
      {loginStage == 0 && (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign In
            </h2>
          </div>
          <div className="bg-white mt-8 py-8 px-6 shadow sm:rounded-lg sm:mx-auto sm:w-full sm:max-w-md">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-mobstr-purple focus:border-mobstr-purple sm:text-sm"
                id="email"
                type="email"
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-mobstr-purple focus:border-mobstr-purple sm:text-sm"
                id="password"
                type="password"
              />
            </div>
            <div className="flex flex-col items-center gap-4">
              {!mutation.isLoading && (
                <button
                  className="text-white bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm w-full px-5 py-2.5 text-center"
                  type="button"
                  onClick={onLogin}
                >
                  Login
                </button>
              )}
              {mutation.isLoading && (
                <button
                  disabled
                  type="button"
                  className="text-white bg-mobstr-purple focus:ring-4 focus:ring-mobstr-purple font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                >
                  <svg
                    role="status"
                    className="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Logging in...
                </button>
              )}
              <a
                className="inline-block text-sm text-blue-500 hover:text-blue-700 mt-4 sm:mt-0"
                href="#"
                onClick={() => navigate("/forgotpassword")}
              >
                Forgot Password?
              </a>
            </div>
          </div>
        </div>
      )}

      {loginStage == 1 && (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="m-6 text-center text-3xl font-extrabold text-gray-900">
              Two Factor Authentication
            </h2>
            <div
              className="relative p-4 mb-6 text-sm bg-[#2c4251] text-white rounded-lg"
              role="alert"
            >
              <div className="flex items-start">
                <div>
                  A login verification code has been emailed to you, please
                  enter the code below to proceed.
                </div>
              </div>
              <button
                type="button"
                className="absolute top-1 right-1 inline-flex p-1.5 rounded-lg hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:hover:bg-blue-300 dark:focus:ring-blue-400"
                aria-label="Close"
              >
                <svg
                  className="w-3 h-3"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="bg-white py-8 px-6 shadow sm:rounded-lg sm:mx-auto sm:w-full sm:max-w-md">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="code"
              >
                Verification Code
              </label>
              <input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-mobstr-purple focus:border-mobstr-purple sm:text-sm"
                id="code"
                type="text"
              />
            </div>
            <div className="flex flex-col items-center gap-4">
              {!mutation.isLoading && (
                <button
                  className="text-white bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm w-full px-5 py-2.5 text-center"
                  type="button"
                  onClick={onCodeSubmit}
                >
                  Verify
                </button>
              )}
              {mutation.isLoading && (
                <button
                  disabled
                  type="button"
                  className="text-white bg-mobstr-purple focus:ring-4 focus:ring-mobstr-purple font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                >
                  <svg
                    role="status"
                    className="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Verifying...
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      <ModalLoginError
        open={openVerificationModal}
        setOpen={setOpenVerificationModal}
      />

      <ErrorDialog
        title="Login Error"
        open={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        onConfirm={() => setShowErrorDialog(false)}
      >
        Please check your email and password are entered correctly and try
        again.
      </ErrorDialog>
      <ErrorDialog
        title="Login Error"
        open={showCodeErrorDialog}
        onClose={() => setShowCodeErrorDialog(false)}
        onConfirm={() => setShowCodeErrorDialog(false)}
      >
        Please check you have entered the code correctly.
      </ErrorDialog>
    </>
  );
}

export function ModalLoginError({ open, setOpen }) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Unverified Account
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          You have not yet verified your account. If you have a
                          verification code enter it below to continue, or
                          contact support.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="text-white bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
                    ref={cancelButtonRef}
                  >
                    Verify Account
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel Login
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
