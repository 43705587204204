import React, { useState } from "react";
import AppChooser from "../AppChooser/AppChooser";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

export default function ThreatModal({
  showThreatModal,
  threatObj,
  setShowThreatModal,
}) {
  return (
    <>
      {showThreatModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-full max-w-3xl lg:max-w-6xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-2xl lg:text-3xl font-semibold">
                    Details
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-50 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowThreatModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-50 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  {threatObj.details && (
                    <p className="text-sm mb-3">
                      The details below offer the line file and line number of
                      the detected issue
                    </p>
                  )}
                  {threatObj.details && (
                    <div className="overflow-y-auto h-48 lg:h-60 border-2 rounded p-2">
                      {threatObj.threatId == 2 &&
                        JSON.parse(threatObj.details).map((tdd, index) => {
                          if (
                            tdd.File.split("/")[
                              tdd.File.split("/").length - 1
                            ].indexOf("BNDLTOOL.SF") > -1 ||
                            tdd.File.split("/")[
                              tdd.File.split("/").length - 1
                            ].indexOf("MANIFEST.MF") > -1
                          ) {
                            return (
                              <div key={index}>
                                <p style={{ color: "green" }}>
                                  {
                                    tdd.File.split("/")[
                                      tdd.File.split("/").length - 1
                                    ]
                                  }
                                  :{tdd.StartLine}
                                </p>
                                <div className="text-xs border-2 border-gray-300 m-2 p-2 bg-gray-100">
                                  {tdd.Match}
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div key={index}>
                                <p style={{ fontWeight: "bold" }}>
                                  {
                                    tdd.File.split("/")[
                                      tdd.File.split("/").length - 1
                                    ]
                                  }
                                  :{tdd.StartLine}
                                </p>
                                <div className="text-xs border-2 border-gray-300 m-2 p-2 bg-gray-100">
                                  {tdd.Match}
                                </div>
                              </div>
                            );
                          }
                        })}

                      {threatObj.threatId != 2 && (
                        <>
                          {JSON.parse(threatObj.details).map((tdd, index) => {
                            return (
                              <div key={index}>
                                <p className="text-sm italic">
                                  {tdd.file
                                    .replace("resources/", "")
                                    .replace("sources", "")}
                                  :<b>{tdd.line}</b>
                                </p>
                                <SyntaxHighlighter
                                  style={docco}
                                  wrapLongLines="true"
                                  className="m-2 p-2 text-xs"
                                >
                                  {tdd.sample}
                                </SyntaxHighlighter>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  )}
                  <h2 className="font-bold mt-4 mb-3">Explanation</h2>

                  <div className="overflow-y-auto h-16 lg:h-20 border-2 rounded p-2 mb-3 text-sm">
                    {threatObj.threat.explanation}
                  </div>

                  <h2 className="font-bold mt-4 mb-3">Impact</h2>

                  <div className="overflow-y-auto h-16 lg:h-20 border-2 rounded p-2 mb-3 text-sm">
                    {threatObj.threat.impact}
                  </div>

                  <h2 className="font-bold mt-4 mb-3">How to Review</h2>

                  <div className="overflow-y-auto h-16 lg:h-20 border-2 rounded p-2 text-sm">
                    {threatObj.threat.howtoreview}
                  </div>
                </div>

                <button
                  className="text-white bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
                  type="button"
                  style={{ width: 200, margin: "auto", marginBottom: 10 }}
                  onClick={() => setShowThreatModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
