import React from "react";
import axios from "axios";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { formatDistance } from "date-fns";

export default function Inbox() {
  const inbox = useQuery(["inbox"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/inbox`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  return (
    <>
      <div className="flex justify-between">
        <h2 className="text-xl font-medium">Latest Notifications</h2>
      </div>
      <ul role="list" className="space-y-3 mt-6">
        {inbox.data?.data.map((item) => {
          return (
            <li
              key={item.id}
              className="bg-white shadow overflow-hidden px-4 py-4 sm:px-6 sm:rounded-md"
            >
              <div className="flex justify-between items-center">
                <h3 className="text-md font-semibold">{item.subject}</h3>
                <p className="text-xs text-gray-400">
                  {formatDistance(new Date(item.createdAt), new Date(), {
                    addSuffix: true,
                  })}
                </p>
              </div>
              <div className="text-sm italic text-gray-400 pt-2">
                {item.message}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
}
