import React from "react";
import { Link } from "react-router-dom";

export default function MenuItem({
  children,
  displayName,
  linkPath,
  hasNumber,
  closeSidebar,
}) {
  if (hasNumber === undefined) {
    return (
      <>
        <li className="pt-0 border-b-white border-b">
          <Link
            to={`/${linkPath}`}
            className="flex items-center p-4 text-base font-normal text-white rounded-lg dark:text-white hover:bg-gray-600 dark:hover:bg-gray-700"
            onClick={closeSidebar}
          >
            {children}
            <span className="flex-1 ml-3 whitespace-nowrap">{displayName}</span>
          </Link>
        </li>
      </>
    );
  } else {
    return (
      <>
        <li className="pt-0 border-b-white border-b">
          <Link
            to={`/${linkPath}`}
            className="flex items-center p-4 text-base font-normal text-white rounded-lg dark:text-white hover:bg-gray-600 dark:hover:bg-gray-700"
            onClick={closeSidebar}
          >
            {children}
            <span className="flex-1 ml-3 whitespace-nowrap">{displayName}</span>
            <span className="inline-flex justify-center items-center p-3 ml-3 w-3 h-3 text-sm font-medium text-white bg-mobstr-purple rounded-full">
              {hasNumber}
            </span>
          </Link>
        </li>
      </>
    );
  }
}
