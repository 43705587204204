import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Gauge from "../Gauge/Gauge";
import AddAppModal from "./AddAppModal";
import EditAppModal from "./EditAppModal";
import ConfirmDialog from "../Dialog/ConfirmDialog";
import StarRating from "../StarRating/StarRating";
import { Animated } from "react-animated-css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ReactTags from "react-tag-autocomplete";
import "./tags.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faApple,
  faAndroid,
  faChrome,
} from "@fortawesome/free-brands-svg-icons";
import { CheckCircleIcon } from "@heroicons/react/solid";

export default function AppIndex() {
  const [currentPage, setCurrentPage] = useState(1);
  const [appsPerPage] = useState(10);
  const [showAddAppModal, setShowAddAppModal] = useState(false);
  const [showEditAppModal, setShowEditAppModal] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [editAppId, setEditAppId] = useState();
  const [editTags, setEditTags] = useState([]);
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [theseTags, setTheseTags] = useState([]);
  const [orderBy, setOrderBy] = useState("nameASC");
  const [appLimit, setAppLimit] = useState(0);
  const [showPending, setShowPending] = useState(false);
  const [showComplete, setShowComplete] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);

  let navigate = useNavigate();
  const queryClient = useQueryClient();

  const myapps = useQuery(["myapps"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/myapps`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  const myarchive = useQuery(["myarchive"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/myarchive`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  const me = useQuery(["user"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/me`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  useEffect(() => {
    if (me.data) {
      setAppLimit(me.data.data.appLimit);
    }
  }, [me.data]);

  useEffect(() => {
    if (myapps.status === "success" && myapps.data.data) {
      let tagsList = [];
      myapps.data.data.forEach((app) => {
        if (app.tags && JSON.parse(app.tags)) {
          JSON.parse(app.tags).forEach((tag) => {
            if (
              tagsList.findIndex(
                (suggestion) => suggestion.name === tag.name
              ) === -1
            ) {
              tagsList.push(tag);
            }
          });
        }
      });
      setTagSuggestions(tagsList);
    }
  }, [myapps.data]);

  const showAddApp = () => {
    if (myapps.data.data.length >= appLimit) {
      setConfirmOpen(true);
    } else {
      setShowAddAppModal(true);
    }
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = "/mobstr_logo.png";
  };

  const filter = (tag) => {
    const tags = [].concat(theseTags, tag);
    setTheseTags(tags);
  };

  const onDeleteFilter = (i) => {
    const tags = theseTags.slice(0);
    tags.splice(i, 1);
    setTheseTags(tags);
  };

  const normalise = (isPending, score) => {
    if (isPending) {
      return 100;
    } else {
      return score;
    }
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const togglePlatform = (platform) => {
    if (selectedPlatforms.includes(platform)) {
      setSelectedPlatforms(selectedPlatforms.filter((p) => p !== platform));
    } else {
      setSelectedPlatforms([...selectedPlatforms, platform]);
    }
  };

  const combinedFilter = (app) => {
    // Filter by search term
    const searchMatch = app.app.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    // Filter by tags
    const tagMatch =
      theseTags.length === 0 ||
      (app.tags &&
        theseTags.some(
          (tag) =>
            JSON.parse(app.tags).findIndex((t) => t.name === tag.name) > -1
        ));

    // Filter by pending or complete scans
    const pendingMatch = !showPending || app.app.appScanStatus.isQueued;
    const completeMatch = !showComplete || !app.app.appScanStatus.isQueued;

    // Filter by selected platforms
    const platformMatch =
      selectedPlatforms.length === 0 ||
      selectedPlatforms.includes(app.app.platform);

    return (
      searchMatch && tagMatch && pendingMatch && completeMatch && platformMatch
    );
  };

  const sortIt = (data) => {
    let field = orderBy.slice(0, -3);
    let order = orderBy.slice(-3);

    if (field === "score") {
      return order === "ASC"
        ? data.sort(
            (a, b) =>
              normalise(a.app.appScanStatus.isQueued, a.app.score) -
              normalise(b.app.appScanStatus.isQueued, b.app.score)
          )
        : data.sort(
            (a, b) =>
              normalise(b.app.appScanStatus.isQueued, b.app.score) -
              normalise(a.app.appScanStatus.isQueued, a.app.score)
          );
    } else if (field === "date") {
      return order === "ASC"
        ? data.sort((a, b) => new Date(a.dateAdded) - new Date(b.dateAdded))
        : data.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));
    } else if (field === "name") {
      return order === "ASC"
        ? data.sort((a, b) => a.app.name.localeCompare(b.app.name))
        : data.sort((a, b) => b.app.name.localeCompare(a.app.name));
    } else if (field === "status") {
      return order === "ASC"
        ? data.sort(
            (a, b) =>
              a.app.appScanStatus.isQueued - b.app.appScanStatus.isQueued
          )
        : data.sort(
            (a, b) =>
              b.app.appScanStatus.isQueued - a.app.appScanStatus.isQueued
          );
    }

    return data;
  };

  const filteredData = myapps.data?.data?.filter(combinedFilter) || [];
  const sortedData = sortIt(filteredData);
  const totalPages = Math.ceil(filteredData.length / appsPerPage);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [filteredData, currentPage, totalPages]);

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between">
        <h2 className="text-xl font-medium">My Apps</h2>
        <button
          onClick={showAddApp}
          className="text-white bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase mt-4 sm:mt-0"
        >
          Add App
        </button>
      </div>

      <div className="bg-white py-5 sm:py-8 px-4 mt-6 shadow sm:rounded-lg sm:px-10">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          <input
            type="text"
            placeholder="Search Apps"
            value={searchTerm}
            onChange={handleSearch}
            className="px-3 py-1 border border-gray-300 rounded-md focus:ring-mobstr-purple focus:border-mobstr-purple h-11 col-span-1"
          />

          <div className="flex flex-col gap-2 col-span-1">
            <div className="flex items-center">
              <input
                id="showPending"
                type="checkbox"
                checked={showPending}
                onChange={() => setShowPending(!showPending)}
                className="form-checkbox h-5 w-5 text-mobstr-purple border-gray-300 rounded-md"
              />
              <label htmlFor="showPending" className="ml-2 text-gray-700">
                Pending
              </label>
            </div>

            <div className="flex items-center">
              <input
                id="showComplete"
                type="checkbox"
                checked={showComplete}
                onChange={() => setShowComplete(!showComplete)}
                className="form-checkbox h-5 w-5 text-mobstr-purple border-gray-300 rounded-md"
              />
              <label htmlFor="showComplete" className="ml-2 text-gray-700">
                Complete
              </label>
            </div>
          </div>

          <div className="flex flex-col gap-2 col-span-1">
            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-mobstr-purple border-gray-300 rounded-md"
                checked={selectedPlatforms.includes("ios")}
                onChange={() => togglePlatform("ios")}
              />
              <span className="ml-2 text-gray-700">iOS</span>
            </label>

            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-mobstr-purple border-gray-300 rounded-md"
                checked={selectedPlatforms.includes("android")}
                onChange={() => togglePlatform("android")}
              />
              <span className="ml-2 text-gray-700">Android</span>
            </label>

            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-mobstr-purple border-gray-300 rounded-md"
                checked={selectedPlatforms.includes("chrome")}
                onChange={() => togglePlatform("chrome")}
              />
              <span className="ml-2 text-gray-700">Chrome</span>
            </label>
          </div>

          <ReactTags
            tags={theseTags}
            suggestions={tagSuggestions}
            onDelete={onDeleteFilter}
            onAddition={filter}
            classNames={{
              root: "react-tags-filter h-11 col-span-1",
            }}
            placeholderText="Filter by Tag"
          />

          <div className="border border-gray-300 border-solid rounded-lg pl-2 pr-2 h-11 col-span-1">
            <select
              className="w-full border-0 focus:border-0 border-transparent focus:border-transparent focus:ring-0"
              onChange={(e) => setOrderBy(e.target.value)}
            >
              <option value="" disabled>
                Sort by
              </option>
              <option value="nameASC">App Name</option>
              <option value="scoreDES">Score (High to Low)</option>
              <option value="scoreASC">Score (Low to High)</option>
              <option value="statusASC">Pending Status</option>
              <option value="dateDES">Date Added (Newest First)</option>
              <option value="dateASC">Date Added (Oldest First)</option>
            </select>
          </div>
        </div>

        <div className="w-full flex flex-row my-5">
          <ul role="list" className="divide-y divide-gray-200 w-full">
            {sortedData
              .slice((currentPage - 1) * appsPerPage, currentPage * appsPerPage)
              .map(({ app, tags, status }, index) => (
                <Animated animationIn="fadeInUp" animationInDelay={index * 100}>
                  <li key={index} className="py-4">
                    <div className="flex flex-1 justify-between">
                      <span className="relative">
                        <img
                          src={`${app.icon}`}
                          className="rounded-2xl h-16 w-16 sm:h-28 sm:w-28 align-middle border-none cursor-pointer object-contain"
                          onError={addDefaultSrc}
                          onClick={() => navigate(`/app/${app.appId}`)}
                        />
                        <span className="absolute top-0 left-0 h-7 w-7 flex items-center justify-center align-middle text-center bg-mobstr-purple text-white rounded-full">
                          {app.platform == "ios" && (
                            <FontAwesomeIcon icon={faApple} />
                          )}
                          {app.platform == "android" && (
                            <FontAwesomeIcon icon={faAndroid} />
                          )}
                          {app.platform == "chrome" && (
                            <FontAwesomeIcon icon={faChrome} />
                          )}
                        </span>
                      </span>

                      <div
                        className="ml-4 flex flex-1"
                        onClick={() => navigate(`/app/${app.appId}`)}
                      >
                        <div className="text-base font-medium text-gray-900">
                          <div className="flex items-center">
                            {status == "accepted" && (
                              <CheckCircleIcon className="text-mobstr-purple h-5 w-5 mr-2" />
                            )}
                            <h3
                              onClick={() => navigate(`/app/${app.appId}`)}
                              className="flex items-center"
                            >
                              <span className="cursor-pointer mr-2">
                                {app.name}
                              </span>
                              <StarRating rating={app.rating}></StarRating>
                            </h3>
                          </div>
                          {/* <p className={"ml-4 text-lg " + ((app.score<95&&app.score>=90) ? 'text-orange-500': (app.score<90)? 'text-red-700': 'text-green-700')}>Security Score: <b>{app.score}</b></p> */}
                          {app.developer && (
                            <p className="text-sm text-gray-500">
                              {app.developer}
                            </p>
                          )}
                          {/* <p className="ml-4"><b>{app.price}</b> </p> */}

                          {/* IF CRITICAL APP */}
                          {app.isCritical && (
                            <p className="mt-1 text-xs">
                              <span className="inline-flex items-center bg-red-100 text-red-600 text-xs font-medium px-1 py-0.5 rounded">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="red"
                                  className="w-5 h-5 inline-block mr-1"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v4m0 4h.01M21.17 18.5L13.5 3.5a1.5 1.5 0 00-3 0L2.83 18.5a1.5 1.5 0 001.29 2.25h15.76a1.5 1.5 0 001.29-2.25z"
                                  />
                                </svg>
                                Critical App
                              </span>
                            </p>
                          )}

                          {app.genre && (
                            <p className="mt-1 text-xs text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 inline-block mr-1"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                                />
                              </svg>
                              {app.genre}
                            </p>
                          )}
                          {app.installs && (
                            <p className="mt-1 text-xs text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 inline-block mr-1"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                />
                              </svg>
                              {app.installs && `${app.installs}`}
                            </p>
                          )}
                          {app.url && (
                            <p className="mt-1 text-xs text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 inline-block mr-1"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                />
                              </svg>
                              <a href={app.url} target="_blank">
                                View in App Store
                              </a>
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center justify-center">
                        {app.appScanStatus.isQueued ? (
                          <div className="w-24 md:w-36">
                            <Gauge value={-1} />
                          </div>
                        ) : (
                          <div className="w-24 md:w-36">
                            <Gauge value={app.score} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-2 flex flex-1 flex-row">
                      {tags &&
                        JSON.parse(tags).map((tag) => {
                          return (
                            <span
                              className={
                                "text-[9px] inline-flex items-center font-bold leading-sm uppercase px-3 py-1 mr-1 bg-mobstr-purple text-white rounded-full"
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-4 h-4 mr-1"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 6h.008v.008H6V6z"
                                />
                              </svg>

                              {tag.name}
                            </span>
                          );
                        })}
                      {me && me.data && me.data.data?.parentId == null && (
                        <div
                          className="text-xs text-gray-300 flex items-center justify-center cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditAppId(app.appId);
                            setEditTags(JSON.parse(tags));
                            setShowEditAppModal(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                          </svg>
                          <p>Edit Tags</p>
                        </div>
                      )}
                    </div>
                  </li>
                </Animated>
              ))}
          </ul>
        </div>
        {/* Pagination */}
        <nav aria-label="Page navigation example" className="text-right">
          <ul className="inline-flex -space-x-px text-sm">
            <li>
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>
            {[...Array(totalPages).keys()].map((pageNum) => (
              <li key={pageNum}>
                <button
                  onClick={() => setCurrentPage(pageNum + 1)}
                  className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${
                    currentPage === pageNum + 1
                      ? "text-mobstr-purple bg-blue-50"
                      : ""
                  }`}
                >
                  {pageNum + 1}
                </button>
              </li>
            ))}
            <li>
              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700"
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
        {/* Pagination */}
      </div>
      <AddAppModal
        showModal={showAddAppModal}
        setShowModal={setShowAddAppModal}
      />
      {showEditAppModal && (
        <EditAppModal
          setShowModal={setShowEditAppModal}
          appId={editAppId}
          existingTags={editTags}
          suggestions={tagSuggestions}
          queryClient={queryClient}
        />
      )}
      <ConfirmDialog
        title="App Limit Reached"
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={() => setConfirmOpen(false)}
        hideNo={true}
      >
        You have used all of your available app slots. Get in touch to increase
        the limit, or to remove apps!
      </ConfirmDialog>
    </>
  );
}
