import React, { useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  CheckCircleIcon,
  XCircleIcon,
  ArchiveIcon,
} from "@heroicons/react/solid";

export default function ActionsModal({
  showActionsModal,
  setShowActionsModal,
  details,
  loadApp,
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { bundleId } = useParams();

  const mutation = useMutation(
    ({ status, type }) => {
      let token = localStorage.getItem("tokenmob");
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/${type}archiveapp`,
        {
          appId: bundleId,
          status: status,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["myapps"]);
        queryClient.invalidateQueries(["myusersapps"]);
        queryClient.invalidateQueries(["myarchive"]);
        setShowActionsModal(false);
        loadApp();
      },
    }
  );

  const accept = () => {
    mutation.mutate({ status: "accepted", type: "" });
  };

  const archive = () => {
    mutation.mutate({ status: "rejected", type: "" });
  };

  const unArchive = () => {
    mutation.mutate({ status: "", type: "un" });
  };

  return (
    <>
      {showActionsModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-full max-w-md">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-50 outline-none focus:outline-none p-6">
                <div className="flex items-center rounded-t">
                  <img
                    src={`${details.icon}`}
                    alt="App Icon"
                    className="h-24 w-24 rounded-2xl align-middle border-none max-w-full"
                  />
                  <div className="ml-4">
                    <h3 className="text-xl font-bold">{details.name}</h3>
                    <p className="text-sm text-gray-600">{details.developer}</p>
                    <p className="text-sm text-gray-600">{details.version}</p>
                  </div>
                  <button
                    className="ml-auto bg-transparent border-0 text-gray-400 hover:text-gray-600 text-2xl outline-none focus:outline-none"
                    onClick={() => setShowActionsModal(false)}
                  >
                    ×
                  </button>
                </div>
                <div className="relative py-6 flex-auto">
                  <textarea
                    className="w-full border rounded p-2 text-gray-700 focus:border-mobstr-purple"
                    placeholder="Comments"
                    rows="3"
                  ></textarea>
                </div>
                <div className="flex justify-between items-center">
                  {!details.isArchived && (
                    <button
                      className="flex items-center text-white bg-red-600 hover:bg-red-700 font-medium rounded text-sm px-5 py-2.5 uppercase"
                      onClick={archive}
                    >
                      <XCircleIcon className="h-5 w-5 mr-2" />
                      Reject & Archive
                    </button>
                  )}
                  {details.isArchived && (
                    <button
                      className="flex items-center text-white bg-gray-700 hover:bg-gray-800 font-medium rounded text-sm px-5 py-2.5 uppercase"
                      onClick={unArchive}
                    >
                      <ArchiveIcon className="h-5 w-5 mr-2" />
                      Unarchive
                    </button>
                  )}
                  {!details.isArchived && details.status !== "accepted" && (
                    <button
                      className="flex items-center text-white bg-green-600 hover:bg-green-700 font-medium rounded text-sm px-5 py-2.5 uppercase"
                      onClick={accept}
                    >
                      <CheckCircleIcon className="h-5 w-5 mr-2" />
                      Approve
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
