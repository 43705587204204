import React from "react";
import { Line } from "react-chartjs-2";

function AverageRiskTime({ appStats }) {
  // Sample data for the line chart
  const riskData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Operational Critical 1st Party",
        data: [70, 65, 75, 60, 80, 85, 90, 87, 85, 88, 86, 90],
        fill: false,
        borderColor: "rgba(0, 100, 0, 1)",
        tension: 0.1,
        borderWidth: 2,
      },
      {
        label: "Operational Critical 3rd Party",
        data: [50, 55, 65, 48, 70, 72, 75, 74, 73, 76, 78, 80],
        fill: false,
        borderColor: "rgba(0, 255, 0, 1)",
        tension: 0.1,
        borderWidth: 2,
      },
      {
        label: "Non-Operational 1st Party",
        data: [60, 62, 70, 55, 75, 78, 79, 81, 82, 85, 87, 90],
        fill: false,
        borderColor: "rgba(139, 0, 0, 1)",
        tension: 0.1,
        borderWidth: 2,
      },
      {
        label: "Non-Operational 3rd Party",
        data: [45, 52, 60, 50, 65, 68, 70, 72, 73, 75, 78, 80],
        fill: false,
        borderColor: "rgba(255, 99, 132, 1)",
        tension: 0.1,
        borderWidth: 2,
      },
      {
        label: "Overall Risk",
        data: [
          56.25, 58.5, 67.5, 53.25, 72.5, 75.75, 78.5, 79.5, 78.25, 81, 82.25,
          85,
        ],
        fill: false,
        borderColor: "rgba(181, 38, 186, 1)",
        tension: 0.5,
        borderWidth: 4,
        borderDash: [5, 5],
        pointRadius: 8,
      },
    ],
  };

  // Options for the line chart
  const riskOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  return (
    <>
      {/* When actual scoreHistory exists, use the following line instead of the sample data
      <Line data={appStats.scoreHistory} options={riskOptions} />
      */}
      <div className="h-96">
        <Line data={riskData} options={riskOptions} />
      </div>
    </>
  );
}

export default AverageRiskTime;
