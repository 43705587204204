import React, { useState } from "react";
import AppChooser from "../AppChooser/AppChooser";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";

export default function AddAppModal({ showModal, setShowModal }) {
  const [email, setEmail] = useState("");
  const [approver, setApprover] = useState(false);
  const queryClient = useQueryClient();

  // Mutations
  const mutation = useMutation(
    () => {
      let token = localStorage.getItem("tokenmob");
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/adduser`,
        {
          email: email,
          approver: approver,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["myusers"]);
      },
    }
  );

  const clickAddApp = () => {
    mutation.mutate();
    setShowModal(false);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Add an User</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="mb-4 text-slate-500 text-sm leading-relaxed">
                    You can add a user to your account enabling them to add apps
                    and view results. Added users will get an email invite to
                    set a password.
                  </p>

                  <div>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-mobstr-purple focus:border-mobstr-purple block w-full p-2.5"
                      placeholder="Enter Email Address"
                      required
                    />
                    <div className="flex items-center mt-6">
                      <input
                        value={approver}
                        onChange={(e) => setApprover(!approver)}
                        type="checkbox"
                        id="approver"
                        className=" bg-gray-50 border border-gray-300 text-mobstr-purple text-sm rounded-lg focus:ring-mobstr-purple focus:border-mobstr-purple block p-2.5"
                      />
                      <label
                        htmlFor="checked-checkbox"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                      >
                        Approver
                      </label>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 uppercase background-transparent font-bold  px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="text-white bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
                    type="button"
                    onClick={clickAddApp}
                  >
                    Invite
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
